import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import logo from 'images/logo.png';

const styles = theme => ({
  root: {
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
    display: 'grid',
    gridTemplateColumns: '100%',
    gridTemplateRows: '100%',
    placeItems: 'center',
    background: theme.palette.grey[300],
  },
  card: {
    width: '400px',
    maxWidth: '95%',
    height: 'auto',
  },
  imageContainer: {
    height: '145px',
    width: '100%',
    background: 'linear-gradient(90deg,#30aba3,#3c9dd7)',
    display: 'grid',
    placeItems: 'center',
  },
});

const App = ({ classes }) => (
  <div className={classes.root}>
    <Card className={classes.card}>
      <div className={classes.imageContainer}>
        <img src={logo} className={classes.image} alt="Start + Logo" />
      </div>
      <CardContent>
        <Typography component="p">
          Thank you for visiting Cast & Crew's Start+ Onboarding Portal.
        </Typography>
        <br />
        <Typography component="p">
          We are conducting maintenance on the Start+ portal and will be
          unavailable during this maintenance period. You will be able to access
          the Start+ portal after the maintenance is complete.
        </Typography>
        <br />
        <Typography component="p">
          Please contact Product Support team at{' '}
          <a class="link" href="mailto:plussupport@castandcrew.com">
            plussupport@castandcrew.com
          </a>{' '}
          should you require assistance during this maintenance period. We
          appreciate your patience and continued support of Start+.
        </Typography>
      </CardContent>
    </Card>
  </div>
);

export default withStyles(styles)(App);
